
import {
  defineComponent,
  onMounted,
  reactive,
  Ref,
  ref,
  onBeforeUnmount,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import Head from "@/components/Head.vue";
import { areaList } from "@vant/area-data";
import IClassify from "@/interface/IClassify";
import IDepartment from "@/interface/IDepartment";
// import BaseId from "@/interface/BaseId";
import IAddress from "@/interface/IAddress";
import { add, registerFlag, sendCode, departmentList } from "@/api/user";

import { Toast } from "vant";

export default defineComponent({
  name: "Home",
  components: {
    Head,
  },
  setup() {
    // 获取路由参数
    const route = useRoute();
    const router = useRouter();
    const baseId = route.query?.baseId as any;
    const baseName = route.query?.baseName;
    const jumpUrl = route.query?.url;
    document.title = `${baseName}-国家基层儿科培训`;
    let studyLink = ref("https://www.h5.ntppc.org.cn/") as any;

    onMounted(() => {
      let isClear = route.query?.isClear;
      if (isClear == "clear") {
        localStorage.setItem("phoneRegister", "");
      } else {
        checkHasRegister();
      }
      if (jumpUrl) {
        studyLink.value = jumpUrl;
      }
      registerFlagGet(baseId);
      _getDepartment();
    });
    const loginFormRef: Ref<HTMLElement | null> = ref(null);
    const form = reactive({
      name: "",
      idNumber: "",
      phone: "",
      smsCode: "",
      department: "",
      departmentCode: "",
      departmentName: "",
      workplace: "",
      showOrganizationClassify: "",
      sex: "",
      trainer: "",
      showRegionCode: "",
      workplaceType: undefined,
    });

    let seconds = ref(120);
    let btnText = ref("发送验证码");
    let flag = ref(0);

    // 转换参数
    let organizationClassify = "";
    let regionCode = "";
    let province = "";
    let city = "";
    let area = "";
    let lock = true;

    const showClassify = ref(false);
    const classifyColumns = ref([
      {
        name: "省级医疗机构",
        value: "1",
      },
      {
        name: "地市医疗机构",
        value: "2",
      },
      {
        name: "社区医疗机构",
        value: "3",
      },
      {
        name: "县级医疗机构",
        value: "4",
      },
      {
        name: "乡镇卫生医疗机构",
        value: "5",
      },
    ]);
    const showAddress = ref(false);
    const showConfirmModal = ref(false);
    const showRegisteredModal = ref(false);
    const departmentVisi = ref(false);
    const depementArr = reactive({ data: [] });

    const didRegistered = ref(false);
    const showOther = ref(false);
    // 校验函数返回 true 表示校验通过，false 表示不通过
    const validatorIdNumber = (val: string) =>
      /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/.test(
        val
      );
    const validatorPhone = (val: string) =>
      /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(val);

    const validatorCode = (val: string) => /^\d{6}$/.test(val);

    // 医疗机构
    const toggleClassify = () => {
      if (flag.value == 1) {
        return false;
      }
      showClassify.value = !showClassify.value;
    };

    const onClassifyConfirm = (value: IClassify) => {
      form.showOrganizationClassify = value.name;
      organizationClassify = value.value;
      showClassify.value = false;
    };

    const _getDepartment = async () => {
      let results1 = await departmentList();
      const { results } = results1 as any;
      depementArr.data = results;
    };
    const confirmDepartment = (value: IDepartment[]) => {
      departmentVisi.value = false;
      let _value = value;
      let code = _value.map((m) => m.id).join("/");
      form.departmentName = _value.map((m) => m.name).join("/");
      form.departmentCode = code;
      if (code == "13/149") {
        showOther.value = true;
      } else {
        showOther.value = false;
        form.department = "";
      }
    };
    const onAddressConfirm = (value: IAddress) => {
      form.showRegionCode = `${value[0].name}${value[1].name}${value[2].name}`;
      regionCode = `${value[0].code},${value[1].code},${value[2].code}`;
      province = value[0].name;
      city = value[1].name;
      area = value[2].name;
      showAddress.value = false;
    };

    // 省市区
    const toggleAddress = () => {
      if (flag.value == 1) {
        return false;
      }
      showAddress.value = !showAddress.value;
    };
    const toggleDepartment = () => {
      if (flag.value == 1) {
        return false;
      }
      departmentVisi.value = !departmentVisi.value;
    };
    const onSubmit = async () => {
      showConfirmModal.value = true;
    };

    const sureSubmit = async () => {
      if (lock) {
        lock = false;
        try {
          const params = {
            ...form,
            userName: form.phone,
            regionCode,
            organizationClassify,
            province,
            city,
            area,
            baseId,
            roleId: 5,
            addStudentDto: {
              trainer: form.trainer,
              teacherId: -1,
            },
          };
          await add(params);
          lock = true;
          localStorage.setItem("phoneRegister", "registered");
          Toast.success("注册信息提交成功");
          router.push({ name: "Flow", query: { jumpUrl } });
        } catch (error) {
          lock = true;
          console.error(error);
        }
      }
    };

    const registerFlagGet = async (value: number) => {
      try {
        const params = {
          baseId: value,
        };
        let results1 = await registerFlag(params);
        const { results } = results1 as any;
        flag.value = results;
      } catch (error) {
        console.error(error);
      }
    };
    const checkHasRegister = () => {
      let flag = localStorage.getItem("phoneRegister");
      if (flag == "registered") {
        showRegisteredModal.value = true;
      }
    };
    const toStudy = () => {
      if (route.query.jumpUrl) {
        window.location.href = route.query.jumpUrl as string;
      } else {
        window.location.href = "https://www.h5.ntppc.org.cn";
      }
    };
    const toStudyLink = () => {
      window.location.href = studyLink.value;
    };
    const hideDidRegister = () => {
      didRegistered.value = false;
    };
    const toFlow = () => {
      router.push({ name: "Flow", query: { jumpUrl } });
    };
    const handleSendCode = async () => {
      if (seconds.value < 120) {
        return;
      }
      if (!form.phone) {
        Toast.fail("请输入手机号");
        return;
      }
      let truePhone = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(form.phone);
      if (!truePhone) {
        Toast.fail("请输入正确格式的手机号");
        return;
      }
      try {
        const params = {
          phone: form.phone,
          type: "register",
        };
        let results1 = await sendCode(params);
        const { code } = results1 as any;
        console.log("results", code);
        if (code == 3) {
          didRegistered.value = true;
          return;
        }
        Toast.success("验证码发送成功");
        // 定时器
        _timer();
      } catch (error) {
        console.error(error);
        Toast.fail("验证码发送失败");
      }
    };
    let inter: number;
    const _timer = () => {
      inter = setInterval(() => {
        seconds.value--;
        btnText.value = `${seconds.value}s后重新发送`;
        if (seconds.value <= 0) {
          clearInterval(inter);
          btnText.value = "发送验证码";
          seconds.value = 120;
        }
      }, 1000);
    };
    onBeforeUnmount(() => {
      inter && clearInterval(inter);
    });
    return {
      form,
      loginFormRef,
      validatorIdNumber,
      validatorPhone,
      validatorCode,
      showClassify,
      toggleClassify,
      classifyColumns,
      onClassifyConfirm,
      showAddress,
      toggleAddress,
      toggleDepartment,
      onAddressConfirm,
      areaList,
      showConfirmModal,
      showRegisteredModal,
      onSubmit,
      sureSubmit,
      registerFlagGet,
      checkHasRegister,
      flag,
      handleSendCode,
      btnText,
      seconds,
      toStudy,
      toFlow,
      didRegistered,
      studyLink,
      toStudyLink,
      hideDidRegister,
      departmentVisi,
      depementArr,
      confirmDepartment,
      showOther,
    };
  },
});
