
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  setup() {
    const route = useRoute();
    const goLearn = () => {
      if (route.query.jumpUrl) {
        window.location.href = route.query.jumpUrl as string;
      } else {
        window.location.href = "https://www.h5.ntppc.org.cn";
      }
    };

    return {
      goLearn,
    };
  },
});
