<template>
  <div class="home-wrapper">
    <Head />
    <section class="form-wrap">
      <van-form
        ref="loginFormRef"
        @submit="onSubmit"
        colon
        :disabled="flag == 1"
      >
        <van-field
          v-model="form.name"
          label="姓名"
          name="name"
          placeholder="请输入姓名"
          :rules="[{ required: true, message: '请输入姓名' }]"
        />
        <van-field
          v-model="form.idNumber"
          label="身份证"
          name="idNumber"
          placeholder="请输入身份证"
          :rules="[
            { required: true, message: '请输入身份证号' },
            { validator: validatorIdNumber, message: '请输入正确的身份证号' },
          ]"
        />
        <van-field
          v-model="form.phone"
          label="手机号"
          name="phone"
          placeholder="请输入手机号"
          :rules="[
            { required: true, message: '请输入手机号' },
            { validator: validatorPhone, message: '请输入正确的手机号' },
          ]"
        >
          <template #button>
            <van-button
              size="small"
              @click="handleSendCode"
              :disabled="seconds < 120"
              type="primary"
              >{{ btnText }}</van-button
            >
          </template>
        </van-field>
        <van-field
          v-model="form.smsCode"
          label="验证码"
          name="phone"
          placeholder="请输入验证码"
          :rules="[
            { required: true, message: '请输入验证码' },
            { validator: validatorCode, message: '请输入正确格式的验证码' },
          ]"
        />
        <van-field
          name="sex"
          label="性别"
          :rules="[{ required: true, message: '请选择性别' }]"
        >
          <template #input>
            <van-radio-group
              v-model="form.sex"
              direction="horizontal"
              :disabled="flag == 1"
            >
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          name="trainer"
          label="学员类型"
          :rules="[{ required: true, message: '请选学员类型' }]"
        >
          <template #input>
            <van-radio-group
              v-model="form.trainer"
              direction="horizontal"
              :disabled="flag == 1"
            >
              <van-radio name="1">医生</van-radio>
              <van-radio name="2">护士</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="form.showOrganizationClassify"
          readonly
          clickable
          name="showOrganizationClassify"
          label="机构分类"
          placeholder="点击选择医疗机构分类"
          :rules="[{ required: true, message: '请选择医疗机构分类' }]"
          @click="toggleClassify"
        />
        <van-field
          name="workplaceType"
          label="机构性质"
          :rules="[{ required: true, message: '医疗机构性质' }]"
        >
          <template #input>
            <van-radio-group
              v-model="form.workplaceType"
              direction="horizontal"
              :disabled="flag == 1"
            >
              <van-radio :name="1">公立</van-radio>
              <van-radio :name="2">私立</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="form.showRegionCode"
          readonly
          clickable
          name="showRegionCode"
          label="省市区"
          placeholder="点击选择省市区"
          @click="toggleAddress"
          :rules="[{ required: true, message: '请选择省市区' }]"
        />
        <!-- <van-field
          v-model="form.department"
          label="部门/科室"
          name="department"
          placeholder="请输入部门/科室"
          :rules="[{ required: true, message: '请输入部门/科室' }]"
        /> -->
        <van-field
          v-model="form.departmentName"
          readonly
          clickable
          name="department"
          label="部门/科室"
          placeholder="点击选择部门/科室"
          @click="toggleDepartment"
          :rules="[{ required: true, message: '请选择部门/科室' }]"
        />
        <van-field
          v-model="form.department"
          name="department"
          label="科室"
          placeholder="请填写科室"
          :rules="[{ required: showOther, message: '请填写科室' }]"
          v-if="showOther"
        />
        <van-field
          v-model="form.workplace"
          label="工作单位"
          name="workplace"
          placeholder="请输入工作单位"
          :rules="[{ required: true, message: '请输入工作单位' }]"
        />
        <div style="margin: 16px">
          <van-button
            round
            block
            type="primary"
            native-type="submit"
            :disabled="flag == 1"
          >
            {{ flag == 1 ? "未开放注册" : "提交" }}
          </van-button>
        </div>
      </van-form>

      <van-popup v-model:show="showClassify" position="bottom">
        <van-picker
          title="医疗机构分类"
          :columns="classifyColumns"
          :columns-field-names="{ text: 'name', value: 'value' }"
          @confirm="onClassifyConfirm"
          @cancel="toggleClassify"
        />
      </van-popup>
      <van-popup v-model:show="showAddress" position="bottom">
        <van-area
          title="省市区"
          :area-list="areaList"
          @confirm="onAddressConfirm"
          @cancel="toggleAddress"
        />
      </van-popup>
      <van-popup v-model:show="departmentVisi" position="bottom">
        <van-picker
          title="部门/科室"
          :columns="depementArr.data"
          :columns-field-names="{ text: 'name', value: 'id' }"
          @confirm="confirmDepartment"
          @cancel="departmentVisi = false"
        />
      </van-popup>
      <van-dialog
        v-model:show="showConfirmModal"
        @confirm="sureSubmit"
        title="是否提交填写信息"
        show-cancel-button
        confirm-button-color="#047C93"
      >
        <van-cell title="姓名" :value="form.name" />
        <van-cell title="性别" :value="form.sex === '1' ? '男' : '女'" />
        <van-cell title="身份证号" :value="form.idNumber" />
        <van-cell title="手机号" :value="form.phone" />
        <van-cell
          title="学员类型"
          :value="form.trainer === '1' ? '医生' : '护士'"
        />
        <van-cell title="省市区" :value="form.showRegionCode" />
        <van-cell
          title="部门/科室"
          :value="form.department ? form.department : form.departmentName"
        />
        <van-cell title="省市区" :value="form.showRegionCode" />
        <van-cell title="工作单位" :value="form.workplace" />
        <van-cell title="机构分类" :value="form.showOrganizationClassify" />
        <van-cell
          title="机构性质"
          :value="form.workplaceType === 1 ? '公立' : '私立'"
        />
        <!-- <van-cell title="所属科室" :value="form.department" /> -->
      </van-dialog>
      <van-dialog
        v-model:show="showRegisteredModal"
        :showConfirmButton="false"
        title="您已提交过注册信息"
      >
        <div class="btn-wrap">
          <van-button type="primary" block round size="normal" @click="toStudy"
            >前往培训系统</van-button
          >
          <van-button
            type="default"
            block
            round
            size="normal"
            class="m-t-12"
            @click="toFlow"
            >查看项目培训流程</van-button
          >
        </div>
      </van-dialog>
      <van-dialog
        v-model:show="didRegistered"
        :showConfirmButton="false"
        title="该手机号已注册，您可直接前往学习"
      >
        <div class="link-wrap">
          <div class="btn-wrap">
            <van-button
              type="primary"
              block
              round
              size="normal"
              @click="toStudyLink"
              >确定</van-button
            >
            <van-button
              type="default"
              block
              round
              size="normal"
              class="m-t-12"
              @click="hideDidRegister"
              >不是我的手机号</van-button
            >
          </div>
        </div>
      </van-dialog>
    </section>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  reactive,
  Ref,
  ref,
  onBeforeUnmount,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import Head from "@/components/Head.vue";
import { areaList } from "@vant/area-data";
import IClassify from "@/interface/IClassify";
import IDepartment from "@/interface/IDepartment";
// import BaseId from "@/interface/BaseId";
import IAddress from "@/interface/IAddress";
import { add, registerFlag, sendCode, departmentList } from "@/api/user";

import { Toast } from "vant";

export default defineComponent({
  name: "Home",
  components: {
    Head,
  },
  setup() {
    // 获取路由参数
    const route = useRoute();
    const router = useRouter();
    const baseId = route.query?.baseId as any;
    const baseName = route.query?.baseName;
    const jumpUrl = route.query?.url;
    document.title = `${baseName}-国家基层儿科培训`;
    let studyLink = ref("https://www.h5.ntppc.org.cn/") as any;

    onMounted(() => {
      let isClear = route.query?.isClear;
      if (isClear == "clear") {
        localStorage.setItem("phoneRegister", "");
      } else {
        checkHasRegister();
      }
      if (jumpUrl) {
        studyLink.value = jumpUrl;
      }
      registerFlagGet(baseId);
      _getDepartment();
    });
    const loginFormRef: Ref<HTMLElement | null> = ref(null);
    const form = reactive({
      name: "",
      idNumber: "",
      phone: "",
      smsCode: "",
      department: "",
      departmentCode: "",
      departmentName: "",
      workplace: "",
      showOrganizationClassify: "",
      sex: "",
      trainer: "",
      showRegionCode: "",
      workplaceType: undefined,
    });

    let seconds = ref(120);
    let btnText = ref("发送验证码");
    let flag = ref(0);

    // 转换参数
    let organizationClassify = "";
    let regionCode = "";
    let province = "";
    let city = "";
    let area = "";
    let lock = true;

    const showClassify = ref(false);
    const classifyColumns = ref([
      {
        name: "省级医疗机构",
        value: "1",
      },
      {
        name: "地市医疗机构",
        value: "2",
      },
      {
        name: "社区医疗机构",
        value: "3",
      },
      {
        name: "县级医疗机构",
        value: "4",
      },
      {
        name: "乡镇卫生医疗机构",
        value: "5",
      },
    ]);
    const showAddress = ref(false);
    const showConfirmModal = ref(false);
    const showRegisteredModal = ref(false);
    const departmentVisi = ref(false);
    const depementArr = reactive({ data: [] });

    const didRegistered = ref(false);
    const showOther = ref(false);
    // 校验函数返回 true 表示校验通过，false 表示不通过
    const validatorIdNumber = (val: string) =>
      /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/.test(
        val
      );
    const validatorPhone = (val: string) =>
      /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(val);

    const validatorCode = (val: string) => /^\d{6}$/.test(val);

    // 医疗机构
    const toggleClassify = () => {
      if (flag.value == 1) {
        return false;
      }
      showClassify.value = !showClassify.value;
    };

    const onClassifyConfirm = (value: IClassify) => {
      form.showOrganizationClassify = value.name;
      organizationClassify = value.value;
      showClassify.value = false;
    };

    const _getDepartment = async () => {
      let results1 = await departmentList();
      const { results } = results1 as any;
      depementArr.data = results;
    };
    const confirmDepartment = (value: IDepartment[]) => {
      departmentVisi.value = false;
      let _value = value;
      let code = _value.map((m) => m.id).join("/");
      form.departmentName = _value.map((m) => m.name).join("/");
      form.departmentCode = code;
      if (code == "13/149") {
        showOther.value = true;
      } else {
        showOther.value = false;
        form.department = "";
      }
    };
    const onAddressConfirm = (value: IAddress) => {
      form.showRegionCode = `${value[0].name}${value[1].name}${value[2].name}`;
      regionCode = `${value[0].code},${value[1].code},${value[2].code}`;
      province = value[0].name;
      city = value[1].name;
      area = value[2].name;
      showAddress.value = false;
    };

    // 省市区
    const toggleAddress = () => {
      if (flag.value == 1) {
        return false;
      }
      showAddress.value = !showAddress.value;
    };
    const toggleDepartment = () => {
      if (flag.value == 1) {
        return false;
      }
      departmentVisi.value = !departmentVisi.value;
    };
    const onSubmit = async () => {
      showConfirmModal.value = true;
    };

    const sureSubmit = async () => {
      if (lock) {
        lock = false;
        try {
          const params = {
            ...form,
            userName: form.phone,
            regionCode,
            organizationClassify,
            province,
            city,
            area,
            baseId,
            roleId: 5,
            addStudentDto: {
              trainer: form.trainer,
              teacherId: -1,
            },
          };
          await add(params);
          lock = true;
          localStorage.setItem("phoneRegister", "registered");
          Toast.success("注册信息提交成功");
          router.push({ name: "Flow", query: { jumpUrl } });
        } catch (error) {
          lock = true;
          console.error(error);
        }
      }
    };

    const registerFlagGet = async (value: number) => {
      try {
        const params = {
          baseId: value,
        };
        let results1 = await registerFlag(params);
        const { results } = results1 as any;
        flag.value = results;
      } catch (error) {
        console.error(error);
      }
    };
    const checkHasRegister = () => {
      let flag = localStorage.getItem("phoneRegister");
      if (flag == "registered") {
        showRegisteredModal.value = true;
      }
    };
    const toStudy = () => {
      if (route.query.jumpUrl) {
        window.location.href = route.query.jumpUrl as string;
      } else {
        window.location.href = "https://www.h5.ntppc.org.cn";
      }
    };
    const toStudyLink = () => {
      window.location.href = studyLink.value;
    };
    const hideDidRegister = () => {
      didRegistered.value = false;
    };
    const toFlow = () => {
      router.push({ name: "Flow", query: { jumpUrl } });
    };
    const handleSendCode = async () => {
      if (seconds.value < 120) {
        return;
      }
      if (!form.phone) {
        Toast.fail("请输入手机号");
        return;
      }
      let truePhone = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(form.phone);
      if (!truePhone) {
        Toast.fail("请输入正确格式的手机号");
        return;
      }
      try {
        const params = {
          phone: form.phone,
          type: "register",
        };
        let results1 = await sendCode(params);
        const { code } = results1 as any;
        console.log("results", code);
        if (code == 3) {
          didRegistered.value = true;
          return;
        }
        Toast.success("验证码发送成功");
        // 定时器
        _timer();
      } catch (error) {
        console.error(error);
        Toast.fail("验证码发送失败");
      }
    };
    let inter: number;
    const _timer = () => {
      inter = setInterval(() => {
        seconds.value--;
        btnText.value = `${seconds.value}s后重新发送`;
        if (seconds.value <= 0) {
          clearInterval(inter);
          btnText.value = "发送验证码";
          seconds.value = 120;
        }
      }, 1000);
    };
    onBeforeUnmount(() => {
      inter && clearInterval(inter);
    });
    return {
      form,
      loginFormRef,
      validatorIdNumber,
      validatorPhone,
      validatorCode,
      showClassify,
      toggleClassify,
      classifyColumns,
      onClassifyConfirm,
      showAddress,
      toggleAddress,
      toggleDepartment,
      onAddressConfirm,
      areaList,
      showConfirmModal,
      showRegisteredModal,
      onSubmit,
      sureSubmit,
      registerFlagGet,
      checkHasRegister,
      flag,
      handleSendCode,
      btnText,
      seconds,
      toStudy,
      toFlow,
      didRegistered,
      studyLink,
      toStudyLink,
      hideDidRegister,
      departmentVisi,
      depementArr,
      confirmDepartment,
      showOther,
    };
  },
});
</script>
<style lang="less" scoped>
.home-wrapper {
  background: #f7f8fa;
  .form-wrap {
    margin-top: 10px;
  }
  .btn-wrap {
    margin: 20px;
    .m-t-12 {
      margin-top: 12px;
    }
  }
  .link-wrap {
    margin: 10px 20px;
    text-align: center;
  }
}
</style>
