import axios from "axios";
import { Notify } from "vant";

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: "/api",
  timeout: 60000, // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error: any) => {
  if (error.response) {
    Notify({ type: "danger", message: error.response.status });
  }
  return Promise.reject(error.response);
};

// request interceptor
request.interceptors.request.use((config) => {
  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {
  if (response.data.code === 0) {
    if (response.data.message) {
      Notify({ type: "danger", message: response.data.message });
    }
    return Promise.reject(response.data);
  }
  return response.data;
}, errorHandler);

export default request;
