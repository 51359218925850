<template>
  <div>
    <div class="head-wrap">
      <img src="../assets/head.png" class="img" />
      <div class="btn" @click="goLearn">前往学习</div>
    </div>
    <!-- <div class="section-title title-1">
            <p>线上培训、考核流程</p>
        </div> -->
    <div class="img-wrap">
      <img src="../assets/step1.png" class="img" />
    </div>
    <!-- <div class="section-title title-2">
            <p>线下临床实践、考核及证书发放</p>
        </div> -->
    <div class="img-wrap">
      <img src="../assets/step2.png" class="img" />
    </div>

    <div class="link-wrap">
      <div class="section-title title-3">
        <p>上课方式</p>
      </div>
      <p class="m-b-16">
        您可登录国家基层儿科医护人员培训系统进行在线学习，登录账号为注册手机号，初始密码123456。
      </p>
      <p>电脑端学习地址：</p>
      <a class="m-b-16" href="https://www.ntppc.org.cn/user/login"
        >https://www.ntppc.org.cn/user/login</a
      >
      <p>手机端学习地址：</p>
      <a class="m-b-16" href="https://www.h5.ntppc.org.cn/pages/login/login"
        >https://www.h5.ntppc.org.cn/pages/login/login</a
      >
      <p class="tc wx-tip">您也可以通过项目培训平台官方公众号登入学</p>
      <img src="../assets/qr.jpeg" class="qr" />
    </div>
    <img src="../assets/foot.png" class="img" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  setup() {
    const route = useRoute();
    const goLearn = () => {
      if (route.query.jumpUrl) {
        window.location.href = route.query.jumpUrl as string;
      } else {
        window.location.href = "https://www.h5.ntppc.org.cn";
      }
    };

    return {
      goLearn,
    };
  },
});
</script>
<style lang="less" scoped>
.head-wrap {
  position: relative;
  .btn {
    width: 96px;
    height: 34px;
    background: #028da4;
    border-radius: 17px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 34px;
    text-align: center;
    position: absolute;
    bottom: 24px;
    left: calc(50% - 48px);
  }
}
.img {
  width: 100%;
  height: auto;
}
.img-wrap {
  margin: 16px;
}
.section-title {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #01778b;
  line-height: 42px;
  text-align: center;
  p {
    margin: 0;
    padding: 0;
  }
}
.title-1 {
  margin: 7px auto 16px;
  width: 209px;
  height: 42px;
  background-image: url("../assets/title-bg-1.png");
}
.title-2 {
  margin: 16px auto;
  width: 310px;
  height: 42px;
  background-image: url("../assets/title-bg-2.png");
}
.title-3 {
  margin: 0 auto 16px;
  width: 155px;
  height: 42px;
  background-image: url("../assets/title-bg-3.png");
}
.link-wrap {
  margin: 0 16px 16px 16px;
  padding: 24px 16px 16px 16px;
  background-color: rgba(2, 141, 164, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #01778b;
  line-height: 22px;
  P {
    margin: 0;
    padding: 0;
  }
  .m-b-16 {
    margin-bottom: 16px;
  }
  a {
    color: #2661f6;
    word-wrap: break-word;
  }
  .qr {
    width: 109px;
    height: 109px;
    align-self: center;
  }
  .wx-tip {
    margin: 0 auto 16px;
    width: 231px;
    text-align: center;
  }
}
</style>
