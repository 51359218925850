<template>
  <div class="head-wrap">
    <span class="title">学员信息录入</span>
    <img
      src="../assets/logo1.png"
      srcset="../assets/logo2.png 2x, ../assets/logo3.png 3x"
      class="logo"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Head",
});
</script>
<style lang="less" scoped>
.head-wrap {
  background-image: url("../assets/bg3.png");
  background-size: cover;
  height: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
  }

  .logo {
    width: 70px;
    height: 70px;
  }
}
</style>
