import request from "./index";
import IForm from "@/interface/IForm";
import BaseId from "@/interface/BaseId";
import SmsCode from "@/interface/SmsCode"

export const add = (data: IForm) => {
  return request({
    url: "/user/QRCodeAdd",
    method: "post",
    data,
  });
};

export const registerFlag = (params: BaseId) => {
  return request({
    url: "/base/registerFlag/get",
    method: "get",
    params,
  });
};

export const sendCode = (data: SmsCode) => {
  return request({
    url: "/user/sendSmsCode",
    method: "get",
    params:data,
  });
};

// 字典-获取科室列表
export function departmentList() {
  return request({
    url: "department/list",
    method: "get",
  });
}